import { setInput } from 'state/slices/ui/dash'
import { TabProps } from '../common/Tab'
import Tabs from '../common/Tabs'
import { onlyNumbers } from 'toolbox/format'
import { useAppSelector } from 'state/hooks'
import { selectInputVal } from 'state/slices/ui/form'

function EmptyForm({ tabs, selectedTab }: { tabs: TabProps[]; selectedTab: string }) {
  const inputVal = useAppSelector(selectInputVal)

  return (
    <>
      <div className="title">
        <div className="is-flex">
          <h1>Selected data not found</h1>
        </div>
      </div>
      <Tabs tabs={tabs} variant="basic" mods={'full-width'} />

      <div className="input-container">
        <div className="center">
          <p className="i-label">{selectedTab} No active selected data</p>
          <input
            className="form-input"
            placeholder="0"
            value={inputVal}
            onChange={(e) => setInput(onlyNumbers(e.target.value))}
          />
          <p className="value">=$0</p>
        </div>
        <button className="sp-btn max">Max</button>
      </div>
      <button className="sp-btn primary large">{selectedTab}</button>
      <div className="is-flex spread balances">
        <span className="is-flex">
          <p className="dark">0&nbsp;</p>
          <p className="light">in wallet</p>
        </span>
        <span className="is-flex">
          <p className="dark">0 &nbsp;</p>
          <p className="light">{selectedTab}</p>
        </span>
      </div>
    </>
  )
}

export default EmptyForm
