import { ReactNode } from 'react'
import MetaWalletContext from 'components/wallet/MetaWalletContext'
import { AptosWalletAdapterProvider } from '@aptos-labs/wallet-adapter-react'

export interface SuperWalletProviderProps {
  children: ReactNode
}

const envNet = process.env.REACT_APP_APTOS_NETWORK_SHORT || 'testnet'
const isMainnet = envNet === 'mainnet'

const msafeURL = isMainnet ? 'https://aptos.m-safe.io' : 'https://aptos-testnet.m-safe.io'

function SuperWalletProvider({ children }: SuperWalletProviderProps) {
  return (
    <AptosWalletAdapterProvider
      autoConnect
      onError={(e: Error) => {
        console.log('Wallet Error:', e)
      }}>
      <MetaWalletContext>{children}</MetaWalletContext>
    </AptosWalletAdapterProvider>
  )
}

export default SuperWalletProvider
