import { Wallet } from '@aptos-labs/wallet-adapter-react'
import pontemIcon from 'assets/logos/pontem.svg'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'

const SUPPORTED_BROWSER_LIST = ['Chrome', 'Brave']

export function isUnsupportedBrowser(browser: string) {
  return !SUPPORTED_BROWSER_LIST.includes(browser)
}

export interface BadBrowserProps {
  wallets: Wallet[]
  browser: string
}

export function BadBrowserFallback({ wallets, browser }: BadBrowserProps) {
  function doDisplay() {
    const list = wallets.map((w: Wallet, i: number) => {
      const icon = w.name === 'Pontem' ? pontemIcon : w.icon
      return (
        <div className="funding-wallet-container disabled" key={w.name}>
          <img src={icon} alt={w.name} className="wallet-icon" />
          <h3>{w.name}</h3>
        </div>
      )
    })

    const infoBoxProps: InfoBoxProps = {
      color: 'red',
      showBox: true,
      text: `Unsupported browser deteced: ${browser}`,
      fullWidth: true
    }

    return (
      <div className="account-wallets-outer">
        <InfoBox {...infoBoxProps} />
        <div className="account-wallet-list">{list}</div>
        <h4>
          Due to wallet extension compatibility, we recommend using Chrome or Brave browsers. Wallet
          connection will be disabled, but you can still view the markets and activity pages.
        </h4>
      </div>
    )
  }

  return doDisplay()
}
