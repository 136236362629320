import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState, dispatch } from 'state/store'
import { createSelector } from 'reselect'
import { TabProps } from 'components/common/Tab'
import { BORROW, COLLATERAL, DashTab, selectDashTab, setDashboardTab } from './dash'
import { FormattedPositions, MetaBroker } from 'state/mock'
import { doTx, TxReqPayload, tabToType, TxType } from 'state/thunks/doTx'
import { SignAndSubmitTransactionCallback } from 'state/thunks/doMoneyGun'

export const DEPOSIT_TAB = 'Supply'
export const WITHDRAW_TAB = 'Withdraw'
export const BORROW_TAB = 'Borrow'
export const REPAY_TAB = 'Repay'

export const COLLATERAL_TABS: FormTab[] = [DEPOSIT_TAB, WITHDRAW_TAB]
export const BORROW_TABS: FormTab[] = [BORROW_TAB, REPAY_TAB]

export type FormTab =
  | typeof DEPOSIT_TAB
  | typeof WITHDRAW_TAB
  | typeof BORROW_TAB
  | typeof REPAY_TAB

export const ALL_HISTORY = 'All activity'
export const USER_HISTORY = 'Your activity'

export type HistoryTab = typeof ALL_HISTORY | typeof USER_HISTORY

export const HISTORY_TABS: HistoryTab[] = [ALL_HISTORY, USER_HISTORY]
export interface FormState {
  formOpen: boolean
  activeBroker: MetaBroker
  formTab: FormTab
  inputVal: string
  txReview: boolean
  detailsOpen: boolean
  historyTab: HistoryTab
  multiplyFormOpen: boolean
  multiReviewOpen: boolean
}

export const initialState: FormState = {
  formOpen: false,
  activeBroker: null,
  formTab: DEPOSIT_TAB,
  inputVal: '',
  txReview: false,
  detailsOpen: false,
  historyTab: ALL_HISTORY,
  multiplyFormOpen: false,
  multiReviewOpen: false
}

const slice = createSlice({
  name: 'form',
  initialState,
  reducers: {
    setFormOpen: (s) => {
      s.formOpen = true
    },
    setFormClosed: (s) => {
      s.formOpen = false
      s.inputVal = ''
      s.txReview = false
    },
    setActiveBroker: (s, a: PayloadAction<MetaBroker>) => {
      s.activeBroker = a.payload
    },
    setTab: (s, a: PayloadAction<FormTab>) => {
      s.formTab = a.payload
      s.inputVal = ''
    },
    setInputVal: (s, a: PayloadAction<string>) => {
      s.inputVal = a.payload
    },
    setTxReviewOpen: (s) => {
      s.txReview = true
    },
    setTxReviewClosed: (s) => {
      s.txReview = false
    },
    setMultiReviewOpen: (s) => {
      s.multiReviewOpen = true
    },
    setMultiReviewClosed: (s) => {
      s.multiReviewOpen = false
    },
    setDetailsOpen: (s) => {
      s.detailsOpen = true
    },
    setDetailsClosed: (s) => {
      s.detailsOpen = false
    },
    setHistoryTab: (s, a: PayloadAction<HistoryTab>) => {
      s.historyTab = a.payload
    },
    setMultiplyFormOpen: (s) => {
      s.multiplyFormOpen = true
    },
    setMultiplyFormClosed: (s) => {
      s.multiplyFormOpen = false
    }
  }
})

export default slice.reducer

//selectors
export const selectFormOpen = (s: RootState) => s.ui.form.formOpen
export const selectActiveBroker = (s: RootState) => s.ui.form.activeBroker
export const selectFormTab = (s: RootState) => s.ui.form.formTab
export const selectInputVal = (s: RootState) => s.ui.form.inputVal
export const selectTxReviewOpen = (s: RootState) => s.ui.form.txReview
export const selectDetailsOpen = (s: RootState) => s.ui.form.detailsOpen
export const selectHistoryTab = (s: RootState) => s.ui.form.historyTab
export const selectMultiplyFormOpen = (s: RootState) => s.ui.form.multiplyFormOpen
export const selectMultiReviewOpen = (s: RootState) => s.ui.form.multiReviewOpen

//dispatches
export const {
  setFormOpen,
  setFormClosed,
  setActiveBroker,
  setTab,
  setInputVal,
  setTxReviewOpen,
  setTxReviewClosed,
  setDetailsOpen,
  setDetailsClosed,
  setHistoryTab,
  setMultiplyFormOpen,
  setMultiplyFormClosed,
  setMultiReviewOpen,
  setMultiReviewClosed
} = slice.actions
export const openForm = () => {
  dispatch(setFormOpen())
}
export const closeForm = () => {
  dispatch(setFormClosed())
}
export const openDetails = () => {
  dispatch(setDetailsOpen())
}
export const closeDetails = () => {
  dispatch(setDetailsClosed())
}
export const setFormBroker = (b: MetaBroker) => {
  dispatch(setActiveBroker(b))
}
export const setFormTab = (t: FormTab) => {
  dispatch(setTab(t))
}
export const openTxReview = () => {
  dispatch(setTxReviewOpen())
}
export const closeTxReview = () => {
  dispatch(setTxReviewClosed())
}
export const setTabHistory = (t: HistoryTab) => {
  dispatch(setHistoryTab(t))
}
export const openMultiplyForm = () => {
  dispatch(setMultiplyFormOpen())
}
export const closeMultiplyForm = () => {
  dispatch(setMultiplyFormClosed())
}
export const openMultiReview = () => {
  dispatch(setMultiReviewOpen())
}
export const closeMultiReview = () => {
  dispatch(setMultiReviewClosed())
}

export const openFormWithData = (b: MetaBroker, tab?: FormTab, dashTab?: DashTab) => {
  if (dashTab) {
    setDashboardTab(dashTab)
  }
  setFormBroker(b)
  if (tab) {
    setFormTab(tab)
  }
  openForm()
}

export const openDetailsWithBroker = (b: MetaBroker) => {
  setFormBroker(b)
  openDetails()
}

export const setInput = (val: string) => {
  dispatch(setInputVal(val))
}

//prebuilds
export function switchFormLabels(tab: string) {
  switch (tab) {
    case COLLATERAL:
      return COLLATERAL_TABS
    case BORROW:
      return BORROW_TABS
  }
}
export const selectFormLabelsByDashTab = createSelector([selectDashTab], (tab) => {
  return switchFormLabels(tab)
})

export const selectBuiltFormTabs = createSelector(
  [selectFormTab, selectFormLabelsByDashTab],
  (selectedTab, labels) => {
    const tabs = buildFormTabs(labels, selectedTab)
    return tabs
  }
)

export const buildFormTabs = (labels: FormTab[], selected: FormTab) => {
  const dashTabs: TabProps[] = labels.map((label) => ({
    label,
    active: label === selected,
    onClick: () => dispatch(setTab(label))
  }))
  return dashTabs
}

export const buildHistoryTabs = (selected: HistoryTab) => {
  const historyTabs: TabProps[] = HISTORY_TABS.map((label) => ({
    label,
    active: label === selected,
    onClick: () => setTabHistory(label)
  }))
  return historyTabs
}

export const selectBuiltHistoryTabs = createSelector([selectHistoryTab], (selectedTab) => {
  return buildHistoryTabs(selectedTab)
})

export function formAction(
  tab: FormTab,
  broker: MetaBroker,
  amount: number,
  address: string,
  positions: FormattedPositions,
  signAndSub: SignAndSubmitTransactionCallback,
  brokerNames: string[]
) {
  const txType: TxType = tabToType[tab]
  const payload: TxReqPayload = {
    txType,
    amount,
    broker,
    address,
    positions,
    signAndSub,
    brokerNames
  }

  dispatch(doTx(txType)(payload))
}

export function calcNextFreeEquityVal(tab: FormTab, freeEquityVal: number, inputDollars: number) {
  if (tab === DEPOSIT_TAB || tab === REPAY_TAB) {
    return freeEquityVal + inputDollars
  } else {
    return freeEquityVal - inputDollars
  }
}
