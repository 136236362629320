import { prettyTokenBal } from 'toolbox/format'
import { ParamProps } from '../txForm/Param'
import CloseButton from '../common/CloseButton'
import DataPoint from '../common/DataPoint'
import ParamList from '../txForm/ParamList'
import { TokenMeta } from 'state/tokens'

export interface MultiFormReviewProps {
  closeForm: () => void
  inputVal: string
  token: TokenMeta
  multiParams: ParamProps[]
  closeMultiReview: () => void
  action: () => void
  disabled: boolean
}

export function MultiFormReview({
  closeForm,
  inputVal,
  token,
  multiParams,
  closeMultiReview,
  action,
  disabled
}) {
  return (
    <div className="tx-form">
      <CloseButton cb={closeForm} />
      <div className="title">Review multiply details</div>
      <div className="amount-box">
        <DataPoint
          label="Amount"
          value={`${prettyTokenBal(parseFloat(inputVal))} ${token?.ticker}`}
          light
        />
      </div>

      <ParamList params={multiParams} />
      <div className="review-buttons">
        <button className="sp-btn secondary blue large full-w" onClick={closeMultiReview}>
          <p className="pri">Go back</p>
        </button>
        <button className="sp-btn primary large full-w" onClick={action} disabled={disabled}>
          Multiply
        </button>
      </div>
      <p className="t_c">
        By clicking multiply, I authorize MovePosition to initiate the transaction detailed above
      </p>
    </div>
  )
}
