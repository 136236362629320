import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit'
import { initLoadable, Loadable } from 'state'
import { RootState, dispatch } from 'state/store'
import { types } from '@concordia/super-sdk'
import { isEmptyOrNil } from 'toolbox/account'
import { fetchPointsTotals } from 'state/thunks/fetchPointsTotals'
import { SPointsHistory } from '@concordia/super-sdk/src/io'
import { TabProps } from 'components/common/Tab'

type State = Loadable<types.SPointsTotals>
export const initialState = initLoadable<types.SPointsTotals>(null)

export const INVALID = 'Invalid'
export const DUPLICATE = 'Duplicate'
export const OTHER = 'Other'

export type ReferralErrorType = typeof INVALID | typeof DUPLICATE | typeof OTHER

export interface FullState {
  pointsTotals: State
  ui: {
    pointsTab: PointsTab
    referralModalOpen: boolean
    doReferModalOpen: boolean
    referralSuccess: boolean
    referralBannerOpen: boolean
    referralError: null | ReferralErrorType
  }
}

export const DAILY_POINTS = 'Daily points ranking'
export const TOTAL_POINTS = 'Total points ranking'

export const fullInitialState = {
  pointsTotals: initialState,
  ui: {
    pointsTab: DAILY_POINTS,
    referralModalOpen: false,
    doReferModalOpen: false,
    referralSuccess: false,
    referralBannerOpen: false,
    referralError: null
  }
}
const pointsTotals = createSlice({
  name: 'pointsTotals',
  initialState: fullInitialState,
  reducers: {
    resetPointsTotals: (state: FullState) => {
      state = {
        ...state,
        pointsTotals: initialState
      }
      state.pointsTotals.loadedOnce = false
    },
    setPointsTab: (s, a: PayloadAction<PointsTab>) => {
      s.ui.pointsTab = a.payload
    },
    setReferralModalOpen: (s) => {
      s.ui.referralModalOpen = true
    },
    setReferralModalClosed: (s) => {
      s.ui.referralModalOpen = false
    },
    setDoReferModalOpen: (s) => {
      s.ui.doReferModalOpen = true
    },
    setDoReferModalClosed: (s) => {
      s.ui.doReferModalOpen = false
    },
    setReferralSuccess: (s) => {
      s.ui.referralSuccess = true
    },
    setReferralSuccessClosed: (s) => {
      s.ui.referralSuccess = false
    },
    setReferralBannerOpen: (s) => {
      s.ui.referralBannerOpen = true
    },
    setReferralBannerClosed: (s) => {
      s.ui.referralBannerOpen = false
    },
    setReferralError: (s, a: PayloadAction<string>) => {
      s.ui.referralError = a.payload
    },
    resetReferralError: (s) => {
      s.ui.referralError = null
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPointsTotals.fulfilled, (state, action) => {
      state.pointsTotals.loadedOnce = true
      state.pointsTotals.value = action.payload
      state.pointsTotals.status = 'idle'
    })

    builder.addCase(fetchPointsTotals.pending, (state) => {
      state.pointsTotals.status = 'busy'
    })
  }
})

export const {
  resetPointsTotals,
  setPointsTab,
  setReferralModalOpen,
  setReferralModalClosed,
  setDoReferModalOpen,
  setDoReferModalClosed,
  setReferralSuccess,
  setReferralSuccessClosed,
  setReferralBannerOpen,
  setReferralBannerClosed,
  setReferralError,
  resetReferralError
} = pointsTotals.actions
export default pointsTotals.reducer
export const selectPointsTotals = (s: RootState) => s.app.pointsTotals.pointsTotals.value
export const selectPointsTotalsBusy = (s: RootState) =>
  s.app.pointsTotals.pointsTotals.status === 'busy'
export const selectPointsTotalsLoaded = (s: RootState) => s.app.pointsTotals.pointsTotals.loadedOnce
export const selectPointsTab = (s: RootState) => s.app.pointsTotals.ui.pointsTab
export const selectReferralModalOpen = (s: RootState) => s.app.pointsTotals.ui.referralModalOpen
export const selectDoReferModalOpen = (s: RootState) => s.app.pointsTotals.ui.doReferModalOpen
export const selectReferralSuccess = (s: RootState) => s.app.pointsTotals.ui.referralSuccess
export const selectReferralBannerOpen = (s: RootState) => s.app.pointsTotals.ui.referralBannerOpen
export const selectReferralError = (s: RootState) => s.app.pointsTotals.ui.referralError

export const openReferralModal = () => {
  dispatch(setReferralModalOpen())
}

export const closeReferralModal = () => {
  dispatch(setReferralModalClosed())
}

export const openDoReferModal = () => {
  dispatch(setDoReferModalOpen())
}

export const closeDoReferModal = () => {
  dispatch(setDoReferModalClosed())
}

export const openReferralSuccess = () => {
  dispatch(setReferralSuccess())
}

export const closeReferralSuccess = () => {
  dispatch(setReferralSuccessClosed())
  dispatch(setDoReferModalClosed())
}

export const openReferralBanner = () => {
  dispatch(setReferralBannerOpen())
}

export const closeReferralBanner = () => {
  dispatch(setReferralBannerClosed())
}

export const setReferralErrorText = (text: string) => {
  dispatch(setReferralError(text))
}

export const closeReferralError = () => {
  dispatch(resetReferralError())
  dispatch(setDoReferModalClosed())
}

export const selectLoadedPointsTotals = createSelector(
  [selectPointsTotals, selectPointsTotalsLoaded],
  (bals, loaded) => {
    if (!loaded) {
      return []
    }
    if (isEmptyOrNil(bals)) {
      return []
    }

    return bals
  }
)

export const selectRankedPointsTotals = createSelector([selectLoadedPointsTotals], (points) => {
  if (isEmptyOrNil(points)) {
    return []
  }

  return [...points].sort((a, b) => b.runningtotalpoints - a.runningtotalpoints)
})

export const selectRankedDailyPoints = createSelector([selectLoadedPointsTotals], (points) => {
  if (isEmptyOrNil(points)) {
    return []
  }

  return [...points].sort((a, b) => b.dailypoints - a.dailypoints)
})

export const selectUserRank = createSelector(
  [selectRankedPointsTotals, (state, address) => address],
  (points, address) => {
    if (isEmptyOrNil(points)) {
      //   console.log('empty')
      return 0
    }

    // console.log('address!!!!!', address)
    const user = points.find((p) => p.address === address)
    // console.log('FOUND', user)
    if (!user) {
      return 0
    }

    return points.indexOf(user) + 1
  }
)

export const selectRankedDailyPointsRank = createSelector(
  [selectRankedDailyPoints, (state, address) => address],
  (points, address) => {
    if (isEmptyOrNil(points)) {
      return 0
    }

    const user = points.find((p) => p.address === address)
    if (!user) {
      return 0
    }

    return points.indexOf(user) + 1
  }
)

export const selectTotalRanks = createSelector([selectRankedPointsTotals], (points) => {
  if (isEmptyOrNil(points)) {
    return 0
  }

  return points.length
})

const EMPTY_USER_POINTS: SPointsHistory = {
  id: 0,
  address: '',
  collateraldailydollars: 0,
  liabilitydailydollars: 0,
  dailypoints: 0,
  runningtotalvalue: 0,
  runningtotalpoints: 0,
  runningtotallendingpoints: 0,
  runningtotalborrowingpoints: 0,
  ts: '',
  multiplier: 1,
  ranking: 0,
  ranking_total: 0,
  referal_points: 0,
  referal_multiplier: 1,
  referal_count: '0',
  runningtotalreferalpoints: 0
}

export const selectUserPoints = createSelector(
  [selectLoadedPointsTotals, (state, address) => address],
  (points, address) => {
    if (isEmptyOrNil(points)) {
      return EMPTY_USER_POINTS
    }

    const user = points.find((p) => p.address === address)
    if (!user) {
      return EMPTY_USER_POINTS
    }

    return user
  }
)

export const selectTotalSystemPoints = createSelector([selectLoadedPointsTotals], (points) => {
  if (isEmptyOrNil(points)) {
    return 0
  }

  return points.reduce((acc, p) => acc + p.runningtotalpoints, 0)
})

export const selectMedianPoints = createSelector([selectRankedPointsTotals], (points) => {
  if (isEmptyOrNil(points)) {
    return 0
  }

  const middleIndex = Math.floor(points.length / 2)

  // Check if the length of the array is even or odd
  if (points.length % 2 === 0) {
    // If even, return the average of the two middle values
    return (points[middleIndex - 1].runningtotalpoints + points[middleIndex].runningtotalpoints) / 2
  } else {
    // If odd, return the middle value
    return points[middleIndex].runningtotalpoints
  }
})

//select runningtotalreferalpoints
export const selectTotalReferralPoints = createSelector([selectUserPoints], (points) => {
  if (isEmptyOrNil(points)) {
    return 0
  }

  return points.runningtotalreferalpoints
})

export const selectTopTenRanks = createSelector([selectRankedPointsTotals], (points) => {
  if (isEmptyOrNil(points)) {
    return []
  }

  return points.slice(0, 10)
})

export const selectTopTenDailyRanks = createSelector([selectRankedDailyPoints], (points) => {
  if (isEmptyOrNil(points)) {
    return []
  }

  return points.slice(0, 10)
})

export const selectTier = createSelector([selectUserPoints], (user) => {
  if (isEmptyOrNil(user)) {
    return 0
  }

  const multi = user.multiplier

  if (multi === 2) {
    return 1
  } else if (multi === 1.5) {
    return 2
  } else if (multi === 1.2) {
    return 3
  } else {
    return 4
  }
})

export const selectNextTier = createSelector([selectTier], (currentTier) => {
  switch (currentTier) {
    case 1:
      return 0
    case 2:
      return 1
    case 3:
      return 2
    case 4:
      return 3
    default:
      return 0
  }
})

export const selectNextMulti = createSelector([selectTier], (currentTier) => {
  switch (currentTier) {
    case 1:
      return 2
    case 2:
      return 2
    case 3:
      return 1.5
    case 4:
      return 1.2
    default:
      return 0
  }
})

export const selectClosestDailyPoints = createSelector([selectRankedDailyPoints], (data) => {
  if (isEmptyOrNil(data) || data.length === 0) {
    return { closestTo25: 0, closestTo50: 0, closestTo75: 0 }
  }

  // Calculate the index positions for 25%, 50%, and 75%
  const totalItems = data.length
  const targetIndex25 = Math.round(totalItems * 0.25)
  const targetIndex50 = Math.round(totalItems * 0.5)
  const targetIndex75 = Math.round(totalItems * 0.75)

  let closestTo25 = data[0].dailypoints
  let closestTo50 = data[0].dailypoints
  let closestTo75 = data[0].dailypoints

  let minDiff25 = Infinity
  let minDiff50 = Infinity
  let minDiff75 = Infinity

  const medianPoints =
    totalItems % 2 === 0
      ? (data[totalItems / 2 - 1].dailypoints + data[totalItems / 2].dailypoints) / 2
      : data[Math.floor(totalItems / 2)].dailypoints

  data.forEach((item, index) => {
    const diff25 = Math.abs(index + 1 - targetIndex25)
    if (diff25 < minDiff25) {
      minDiff25 = diff25
      closestTo25 = item.dailypoints
    }

    const diff50 = Math.abs(index + 1 - targetIndex50)
    if (diff50 < minDiff50) {
      minDiff50 = diff50
      closestTo50 = item.dailypoints
    }

    const diff75 = Math.abs(index + 1 - targetIndex75)
    if (diff75 < minDiff75) {
      minDiff75 = diff75
      closestTo75 = item.dailypoints
    }
  })

  return { closestTo25, closestTo50, closestTo75, medianPoints }
})

export const selectNextThreshold = createSelector(
  [selectTier, selectClosestDailyPoints],
  (tier, closest) => {
    switch (tier) {
      case 1:
        return 0
      case 2:
        return closest.closestTo25

      case 3:
        return closest.closestTo50

      case 4:
        return closest.closestTo75
      default:
        return 0
    }
  }
)

export const selectReferralCount = createSelector([selectUserPoints], (user) => {
  if (isEmptyOrNil(user)) {
    return '0'
  }

  return user.referal_count
})

export type PointsTab = typeof DAILY_POINTS | typeof TOTAL_POINTS

export const POINTS_TABS: PointsTab[] = [DAILY_POINTS, TOTAL_POINTS]

export const setTabPoints = (t: PointsTab) => {
  dispatch(setPointsTab(t))
}
export const buildPointsTabs = (selected: string) => {
  const pointsTabs: TabProps[] = POINTS_TABS.map((label) => ({
    label,
    active: label === selected,
    onClick: () => setTabPoints(label)
  }))
  return pointsTabs
}

export const selectBuiltPointsTabs = createSelector([selectPointsTab], (selectedTab) => {
  return buildPointsTabs(selectedTab)
})
