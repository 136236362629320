import { formatPublicKeyDisplay, prettyTokenBal } from 'toolbox/format'
import { ParamProps } from './Param'
import CloseButton from '../common/CloseButton'
import InfoPoint from '../common/InfoPoint'
import DataPoint from '../common/DataPoint'
import ParamList from './ParamList'
import { TokenMeta } from 'state/tokens'
import { AccountInfo } from '@aptos-labs/wallet-adapter-core'
import { BORROW_TAB, DEPOSIT_TAB, REPAY_TAB, WITHDRAW_TAB } from 'state/slices/ui/form'

export interface TxFormReviewProps {
  closeForm: () => void
  selectedTab: string
  account: AccountInfo
  inputVal: string
  token: TokenMeta
  formParams: ParamProps[]
  moreParams: ParamProps[]
  closeTxReview: () => void
  action: () => void
  disabled: boolean
  buttonText: string
}

export function TxFormReview({
  closeForm,
  selectedTab,
  account,
  inputVal,
  token,
  formParams,
  moreParams,
  closeTxReview,
  action,
  disabled,
  buttonText
}) {
  const createMessage = () => {
    switch (selectedTab) {
      case DEPOSIT_TAB: {
        return `Transfer from ${formatPublicKeyDisplay(account?.address)} to Supply vault`
      }
      case WITHDRAW_TAB: {
        return `Transfer from Supply vault to ${formatPublicKeyDisplay(account?.address)}`
      }
      case BORROW_TAB: {
        return `Transfer from Supply vault to ${formatPublicKeyDisplay(account?.address)}`
      }
      case REPAY_TAB: {
        return `Transfer from ${formatPublicKeyDisplay(account?.address)} to Supply vault`
      }
    }
  }
  return (
    <div className="tx-form">
      <CloseButton cb={closeForm} />
      <div className="title">
        <div className="is-flex">
          <InfoPoint value={`Review payment details`} sub={createMessage()} />
        </div>
      </div>
      <div className="amount-box">
        <DataPoint
          label="Amount"
          value={`${prettyTokenBal(parseFloat(inputVal))} ${token?.ticker}`}
          light
        />
      </div>

      <ParamList params={formParams()} more={moreParams} />
      <div className="review-buttons">
        <button className="sp-btn secondary blue large full-w" onClick={closeTxReview}>
          <p className="pri">Go back</p>
        </button>
        <button className="sp-btn primary large full-w" onClick={action} disabled={disabled}>
          {buttonText}
        </button>
      </div>
      <p className="t_c">
        By clicking {selectedTab}, I authorize MovePosition to initiate the transaction detailed
        above
      </p>
    </div>
  )
}
