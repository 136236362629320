import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import { useMemo, useState } from 'react'
import {
  ComposedChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Line
} from 'recharts'
import { formatCurrency, formatDateSimple, formatPercentage } from 'toolbox/format'
import { SimpleSpinner } from './SimpleSpinner'
import { isEmptyOrNil } from 'toolbox/account'
import {
  selectBrokerUtilizationHistoryByName,
  timeTabs
} from '../../state/slices/app/brokerHistories'
import TimeFilterTabs from 'components/common/TimeFilterTabs'
import { useAppSelector } from 'state/hooks'
import { Label } from './Label'

export function BarLineUtilization({ name, isLoaded }: { isLoaded: boolean; name: string }) {
  const [activeData, setActiveData] = useState(null)
  const [activeTab, setActiveTab] = useState('1M')
  const numberOfDays = timeTabs.find((tab) => tab.label === activeTab).days
  const utilData = useAppSelector((state) =>
    selectBrokerUtilizationHistoryByName(state, name, numberOfDays)
  )

  const handleMouseMove = (data) => {
    if (data.activePayload) {
      // Update state with the data of the hovered point
      setActiveData(data.activePayload[0].payload)
    }
  }

  const handleMouseLeave = () => {
    setActiveData(null)
  }

  const filteredAndSortedData = useMemo(() => {
    const filteredData = utilData.filter(
      (d) => d.timestamp * 1000 > Date.now() - numberOfDays * 24 * 60 * 60 * 1000
    )

    const sortedData = filteredData.sort((a, b) => a.timestamp - b.timestamp)

    return sortedData
  }, [utilData, numberOfDays])

  if (isEmptyOrNil(utilData) || !isLoaded)
    return (
      <div className="chart-container">
        <SimpleSpinner />
      </div>
    )

  const chartData = filteredAndSortedData.map((item) => ({
    time: formatDateSimple(item.timestamp / 1000 - 86400),
    totalLend: Number(item.totalLend.toFixed(2)),
    totalBorrow: Number(item.totalBorrow.toFixed(2)),
    util: Number(item?.util * 100).toFixed(2)
  }))

  const lastItem = filteredAndSortedData[filteredAndSortedData.length - 1]

  const utilPoint = activeData
    ? formatPercentage(activeData?.util / 100)
    : formatPercentage(lastItem?.util)

  const lendPoint = activeData
    ? formatCurrency(activeData.totalLend)
    : formatCurrency(lastItem.totalLend)

  const borrowPoint = activeData
    ? formatCurrency(activeData.totalBorrow)
    : formatCurrency(lastItem.totalBorrow)

  const utilDataPoints: DataPointProps[] = [
    {
      label: 'Utilization rate',
      value: utilPoint,
      tooltip: 'Total debt / total collateral. Higher utilization = more interest',
      colorBox: 'black'
    },
    {
      label: 'Total lend',
      value: lendPoint,
      tooltip: 'Daily avg. deposit value USD',
      colorBox: 'green'
    },
    {
      label: 'Total borrow',
      value: borrowPoint,
      tooltip: 'Daily avg. debt value USD',
      colorBox: 'blue'
    }
  ]

  const pointsDisplay = utilDataPoints.map((point) => <DataPoint key={point.label} {...point} />)

  const timeTabsProps = {
    activeTab,
    setActiveTab
  }
  return (
    <div className="chart-container">
      <div className="points-line">
        <div className="cluster-points">{pointsDisplay}</div>
        <TimeFilterTabs {...timeTabsProps} />
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart
          data={chartData}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          barCategoryGap={2}
          barGap={0}
          // barSize={100}
          margin={{
            top: 0,
            bottom: 0
          }}>
          <XAxis
            dataKey="time"
            axisLine={false}
            tickLine={false}
            allowDataOverflow={false}
            interval={'preserveStartEnd'}
          />
          <YAxis
            domain={[0, 'dataMax']}
            yAxisId="left"
            orientation="left"
            stroke="#D9D9D9"
            hide={true}
            allowDataOverflow={false}
            dataKey={'totalLend'}
          />
          <YAxis
            domain={[0, 'dataMax']}
            yAxisId="right"
            orientation="right"
            stroke="black"
            hide={true}
            padding={{ bottom: 150 }}
            allowDataOverflow={false}
          />
          <Tooltip
            content={
              <Label
                value={`${activeData?.util}%`}
                secondValue={formatCurrency(activeData?.totalLend)}
                thirdValue={formatCurrency(activeData?.totalBorrow)}
                labels={{
                  value: 'Utilization (Avg)',
                  secondValue: 'Total Lend (Avg)',
                  thirdValue: 'Total Borrow (Avg)'
                }}
              />
            }
          />
          <Bar
            dataKey="totalLend"
            yAxisId="left"
            fill="rgba(255, 255, 255, 0.10)"
            activeBar={<Rectangle fill="#00DD5C" stroke="#00DD5C" />}
          />
          <Bar
            dataKey="totalBorrow"
            yAxisId="left"
            fill="rgba(255, 255, 255, 0.20)"
            activeBar={<Rectangle fill="#f8d24a" stroke="#1D41FF" />}
          />
          <Line dataKey="util" yAxisId="right" stroke="white" dot={false} strokeWidth={1.5} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  )
}
