import { combineReducers } from 'redux'
import meta from 'state/slices/app/meta'
import brokers from 'state/slices/app/brokers'
import priceHistories from 'state/slices/app/priceHistories'
import brokerHistories from 'state/slices/app/brokerHistories'
import pointsTotals from 'state/slices/app/points'
import strategies from 'state/slices/app/strategies'

export const app = combineReducers({
  meta,
  brokers,
  priceHistories,
  brokerHistories,
  pointsTotals,
  strategies
})
