import search from 'assets/icons/search-white.svg'
import { onlyAlpha } from 'toolbox/format'

export interface SearchBarProps {
  value: string
  update: (s: string) => void
  execute?: () => void
  baseline?: boolean
  placeHolder?: string
  noMargin?: boolean
}

function SearchBar({ value, update, execute, baseline, placeHolder, noMargin }: SearchBarProps) {
  const baseClass = baseline ? 'is-baseline' : ''
  const noMarginClass = noMargin ? 'no-margin' : ''
  return (
    <div className={`search-container ${baseClass} ${noMarginClass}`}>
      <img src={search} alt="search" />
      <input
        value={value}
        onChange={(e) => update(onlyAlpha(e.target.value))}
        placeholder={placeHolder ? placeHolder : 'Search'}
      />
      {execute && <button onClick={execute}>Filter</button>}
    </div>
  )
}

export default SearchBar
