import { useState } from 'react'
import { formatCurrency, prettyTokenBal } from 'toolbox/format'
import ActionCircle from '../common/ActionCircle'
import {
  BORROW_TABS,
  COLLATERAL_TABS,
  openFormWithData,
  selectActiveBroker
} from 'state/slices/ui/form'
import { useAppSelector } from 'state/hooks'
import { calcUtil } from 'toolbox/calc'
import { selectPortfolioLoaded } from 'state/slices/user/portfolio'
import {
  calcBorrowData,
  calcSupplyData,
  selectFormattedPositions,
  selectTotalCollateral
} from 'state/slices/user/portfolio'
import { BORROW, COLLATERAL } from 'state/slices/ui/dash'

export interface LabelRowProps {
  balance: number
  dollars: number
  name: string
  label?: string
  isSupply?: boolean
  simple?: boolean
  walletBalance?: number
}
export function LabelRow({
  balance,
  dollars,
  name,
  label,
  isSupply,
  simple,
  walletBalance
}: LabelRowProps) {
  const [isHovered, setIsHovered] = useState(false)
  const b = useAppSelector(selectActiveBroker)
  const userCollat = useAppSelector(selectTotalCollateral)
  const positions = useAppSelector(selectFormattedPositions)
  const isLoadedUser = useAppSelector(selectPortfolioLoaded)

  if (!b) return null
  const formTabs = isSupply ? COLLATERAL_TABS : BORROW_TABS
  const dashTab = isSupply ? COLLATERAL : BORROW
  const openAdd = () => openFormWithData(b, formTabs[0], dashTab)
  const open = () => openFormWithData(b, formTabs[1], dashTab)

  const hasSupplied = userCollat > 0
  const hasPower = 0 < 1
  const canBorrow = hasSupplied && hasPower
  const utilization = calcUtil(b)
  const borrowData = calcBorrowData(positions, b)
  const supplyData = calcSupplyData(positions, b, utilization)
  const hasWalletBalance = walletBalance > 0
  const disAddDeposit = !isLoadedUser || !hasWalletBalance
  const disAddBorrow = !isLoadedUser || !canBorrow
  const disRemoveDeposit = !isLoadedUser || !supplyData.hasPosition
  const disRemoveBorrow = !isLoadedUser || !borrowData.hasPosition

  const disabledAddSwitch = () => {
    return isSupply ? disAddDeposit : disAddBorrow
  }

  const disabledMinusSwitch = () => {
    return isSupply ? disRemoveDeposit : disRemoveBorrow
  }

  const buttons = (
    <div className="broker-buttons">
      <ActionCircle cb={openAdd} disabled={disabledAddSwitch()} isAdd isSupply={isSupply} />
      <ActionCircle cb={open} disabled={disabledMinusSwitch()} isSupply={isSupply} />
    </div>
  )

  if (simple) {
    return (
      <div className="label-row simple">
        <p>{label ? label : name}</p>
        <div className="split">
          <p>
            {prettyTokenBal(balance)} {label ? name : ''}&nbsp;
          </p>
          <p className="light">{formatCurrency(dollars)}</p>
        </div>
      </div>
    )
  }
  return (
    <div
      className="label-row "
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}>
      <p>{label ? label : name}</p>
      <div className="split">
        {isHovered ? (
          buttons
        ) : (
          <>
            <p>
              {prettyTokenBal(balance)} {label ? name : ''}&nbsp;
            </p>
            <p className="light">{formatCurrency(dollars)}</p>
          </>
        )}
      </div>
    </div>
  )
}
