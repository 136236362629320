import closeIcon from 'assets/icons/close.svg'
import closeWhite from 'assets/icons/close-white.svg'

export interface CloseButtonProps {
  cb: () => any
  white?: boolean
}

function CloseButton({ cb, white }: CloseButtonProps) {
  return <img src={closeWhite} alt="close" className="close" onClick={cb} />
}

export default CloseButton
