import Dashboard from 'pages/Dashboard'
import { Route, Routes } from 'react-router-dom'
import { Markets } from 'pages/Markets'
import { Activity } from 'pages/Activity'
import { Points } from 'pages/Points'
import { Multiply } from 'pages/Multiply'

export function Routing() {
  const routes = (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/markets" element={<Markets />} />
      <Route path="/activity" element={<Activity />} />
      {/* <Route path="/points" element={<Points />} /> */}
      {/* <Route path="/multiply" element={<Multiply />} /> */}
    </Routes>
  )

  return routes
}
