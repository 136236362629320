import aptCircle from 'assets/logos/apt-circle.svg'
import aptSquare from 'assets/logos/apt-square.svg'
import usdcCircle from 'assets/logos/usdc-circle.svg'
import thAPTLogo from 'assets/logos/thAPT.svg'
import sthAPTLogo from 'assets/logos/sthAPT.png'
import amAPTLogo from 'assets/logos/amAPT.png'
import stAPTLogo from 'assets/logos/stAPT.png'
import thlLogo from 'assets/logos/thl.svg'
import usdcWHLogo from 'assets/logos/usdcWH.svg'
import usdtLZLogo from 'assets/logos/usdtLZ.svg'
import usdtWHLogo from 'assets/logos/usdtWH.svg'
import wethLZLogo from 'assets/logos/wethLZ.svg'
import wethWHLogo from 'assets/logos/wethWH.svg'
import movementUSDTLogo from 'assets/logos/movement-usdt.svg'
import movementUSDCLogo from 'assets/logos/movement-usdc.svg'
import movementWETHLogo from 'assets/logos/movement-weth.svg'
import movementWBTCLogo from 'assets/logos/movement-wbtc.svg'
import movementLogo from 'assets/logos/movement.svg'
import stMoveLogo from 'assets/logos/stMOVE.svg'

export type ChainId = string
export interface ChainMeta {
  chainID: string
  logoURL: string
  name: string
  description: string
}

export interface TokenMeta {
  chainID: ChainId
  logo: string
  ticker: string
  name: string
  description: string
  projectURL: string
  displayName: string
}

export interface ChainMetaMap {
  [key: ChainId]: ChainMeta
}

export interface TokenMetaMap {
  [key: string]: TokenMeta
}

export const DEFAULT_CHAIN: ChainMeta = {
  chainID: '',
  logoURL: '',
  name: '',
  description: ''
}

export const DEFAULT_TOKEN: TokenMeta = {
  chainID: '',
  logo: '',
  ticker: '',
  name: '',
  description: '',
  projectURL: '',
  displayName: ''
}

//chains
export const CHAIN_APTOS = 'Aptos'
//logos
export const aptosLogo = aptCircle
export const aptosChainLogo = aptSquare
export const usdcLogo = usdcCircle
//tokens
const APTOS = 'aptos'
const APT_USDC = 'usdc'
export const MOVEMENT_USDT_ASSET_NAME = 'movement-usdt'
export const MOVEMENT_USDC_ASSET_NAME = 'movement-usdc'
export const MOVEMENT_WETH_ASSET_NAME = 'movement-weth'
export const MOVEMENT_WBTC_ASSET_NAME = 'movement-wbtc'
const THAPT = 'thapt'
const STH_APT = 'sthapt'
const AM_APT = 'amapt'
const STAKED_APT = 'stapt'
const THALA = 'thl'
const WETH_WH = 'wethwh'
const WETH_LZ = 'weth'
const USDT_LZ = 'usdtlz'
const USDC_WH = 'usdcwh'
const USDT_WH = 'usdtwh'
export const MOVEMENT_NAME = 'movement-move'
export const STAKED_MOVMENT_NAME = 'movement-staked-move'

export interface MintMap {
  [key: string]: number
}

//map chain ids to chain meta
export const chains: ChainMetaMap = {
  [CHAIN_APTOS]: {
    name: 'Aptos',
    chainID: CHAIN_APTOS,
    logoURL: movementLogo,
    description: 'Aptos'
  }
}

//token meta objects
export const aptos: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: APTOS,
  ticker: 'APT',
  description: '',
  logo: aptosLogo,
  projectURL: '',
  displayName: 'Aptos'
}

export const apt_usdc: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: APT_USDC,
  ticker: 'zUSDC',
  description: '',
  logo: usdcLogo,
  projectURL: '',
  displayName: 'USD Coin (LayerZero)'
}

export const MovementUSDTMeta: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: MOVEMENT_USDT_ASSET_NAME,
  ticker: 'USDT',
  description: '',
  logo: movementUSDTLogo,
  projectURL: '',
  displayName: 'USD Tether'
}

export const MovementUSDCMeta: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: MOVEMENT_USDC_ASSET_NAME,
  ticker: 'USDC',
  description: '',
  logo: movementUSDCLogo,
  projectURL: '',
  displayName: 'USD Coin'
}

export const MovementWETHMeta: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: MOVEMENT_WETH_ASSET_NAME,
  ticker: 'WETH',
  description: '',
  logo: movementWETHLogo,
  projectURL: '',
  displayName: 'Wrapped Ethereum'
}

export const MovementWBTCMeta: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: MOVEMENT_WBTC_ASSET_NAME,
  ticker: 'WBTC',
  description: '',
  logo: movementWBTCLogo,
  projectURL: '',
  displayName: 'Wrapped Bitcoin'
}

export const MovementMeta: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: MOVEMENT_NAME,
  ticker: 'MOVE',
  description: '',
  logo: movementLogo,
  projectURL: '',
  displayName: 'Movement'
}

export const StakedMovementMeta: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: STAKED_MOVMENT_NAME,
  ticker: 'stMOVE',
  description: '',
  logo: stMoveLogo,
  projectURL: '',
  displayName: 'Staked Movement'
}

export const thAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: THAPT,
  ticker: 'thAPT',
  description: '',
  logo: thAPTLogo,
  projectURL: '',
  displayName: 'Thala APT'
}

export const sthAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: STH_APT,
  ticker: 'sthAPT',
  description: '',
  logo: sthAPTLogo,
  projectURL: '',
  displayName: 'Staked Thala APT'
}

export const amAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: AM_APT,
  ticker: 'amAPT',
  description: '',
  logo: amAPTLogo,
  projectURL: '',
  displayName: 'Amnis APT'
}

export const stakedAPT: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: STAKED_APT,
  ticker: 'stAPT',
  description: '',
  logo: stAPTLogo,
  projectURL: '',
  displayName: 'Staked Amnis APT'
}

export const thala: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: THALA,
  ticker: 'THL',
  description: '',
  logo: thlLogo,
  projectURL: '',
  displayName: 'Thala'
}

export const wrappedEthWh: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: WETH_WH,
  ticker: 'whWETH',
  description: '',
  logo: wethWHLogo,
  projectURL: '',
  displayName: 'Wrapped Ethereum (Wormhole)'
}

export const wrappedEthLZ: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: WETH_LZ,
  ticker: 'zWETH',
  description: '',
  logo: wethLZLogo,
  projectURL: '',
  displayName: 'Wrapped Ethereum (LayerZero)'
}

export const usdtLZ: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDT_LZ,
  ticker: 'zUSDT',
  description: '',
  logo: usdtLZLogo,
  projectURL: '',
  displayName: 'USD Tether (LayerZero)'
}

export const usdcWH: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDC_WH,
  ticker: 'whUSDC',
  description: '',
  logo: usdcWHLogo,
  projectURL: '',
  displayName: 'USD Coin (Wormhole)'
}

export const usdtWH: TokenMeta = {
  chainID: CHAIN_APTOS,
  name: USDT_WH,
  ticker: 'whUSDT',
  description: '',
  logo: usdtWHLogo,
  projectURL: '',
  displayName: 'USD Tether (Wormhole)'
}

export const tokenMetaMap: TokenMetaMap = {
  [APTOS]: aptos,
  [APT_USDC]: apt_usdc,
  [MOVEMENT_USDT_ASSET_NAME]: MovementUSDTMeta,
  [MOVEMENT_USDC_ASSET_NAME]: MovementUSDCMeta,
  [MOVEMENT_WETH_ASSET_NAME]: MovementWETHMeta,
  [MOVEMENT_WBTC_ASSET_NAME]: MovementWBTCMeta,
  [MOVEMENT_NAME]: MovementMeta,
  [STAKED_MOVMENT_NAME]: StakedMovementMeta,
  [THAPT]: thAPT,
  [STH_APT]: sthAPT,
  [AM_APT]: amAPT,
  [STAKED_APT]: stakedAPT,
  [THALA]: thala,
  [WETH_WH]: wrappedEthWh,
  [WETH_LZ]: wrappedEthLZ,
  [USDT_LZ]: usdtLZ,
  [USDC_WH]: usdcWH,
  [USDT_WH]: usdtWH
}
