import { useMemo, useState } from 'react'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine
} from 'recharts'
import { MetaBroker } from 'state/mock'
import { SDK_CONTEXT } from 'state/context'
import { SimpleSpinner } from './SimpleSpinner'
import DataPoint, { DataPointProps } from 'components/common/DataPoint'
import { formatPercentage } from 'toolbox/format'
import { calcAPY } from 'toolbox/calc'
import { isEmptyOrNil } from 'toolbox/account'
import { Label } from './Label'

export function InterestRateModel({ b }: { b: MetaBroker }) {
  const [activeData, setActiveData] = useState(null)
  const [isHover, setIsHover] = useState(false)
  const currentUtil = b?.utilization * 100

  const handleMouseMove = (data) => {
    setIsHover(true)
    if (data.activePayload) {
      setActiveData(data.activePayload[0].payload)
    }
  }

  const handleMouseLeave = () => {
    setActiveData(null)
    setIsHover(false)
  }

  const curveData = b?.interestRateCurve

  const chartData = useMemo(() => {
    const data = []
    if (isEmptyOrNil(curveData)) return data
    for (let i = 0; i < 200; i++) {
      const utilizationRate = i / 200
      const borrowRate = (
        SDK_CONTEXT.superSdk.getInterestRate(utilizationRate, curveData) * 100
      ).toFixed(2)
      const lendRate = (
        SDK_CONTEXT.superSdk.getInterestRate(utilizationRate, curveData) *
        utilizationRate *
        100
      ).toFixed(2)

      data.push({
        utilizationRate: utilizationRate * 100,
        borrowRate: Number(borrowRate),
        lendRate: Number(lendRate)
      })
    }
    return data
  }, [curveData])

  const closestRate = useMemo(() => {
    if (isEmptyOrNil(chartData)) return 0
    const closest = chartData.reduce((prev, curr) => {
      return Math.abs(curr.utilizationRate - currentUtil) <
        Math.abs(prev.utilizationRate - currentUtil)
        ? curr
        : prev
    })
    return closest.utilizationRate
  }, [chartData, currentUtil])

  if (!b) {
    return <SimpleSpinner />
  }

  const borrowRate = b.interestRate
  const borrowAPY = calcAPY(borrowRate)
  const lendRate = borrowAPY * b.utilization

  const currentRates = {
    borrowRate: parseFloat(borrowRate.toFixed(4)),
    lendRate: parseFloat(lendRate.toFixed(4))
  }

  const interestDataPoints: DataPointProps[] = [
    {
      label: 'Earn APY',
      value: activeData
        ? formatPercentage(activeData.lendRate / 100)
        : formatPercentage(currentRates.lendRate),
      tooltip: 'High utilization = more earnings, boosting lender interest',
      colorBox: 'green'
    },
    {
      label: 'Borrow APR',
      value: activeData
        ? formatPercentage(activeData.borrowRate / 100)
        : formatPercentage(currentRates.borrowRate),
      tooltip: 'High utilization = more demand, increasing borrower interest',
      colorBox: 'blue'
    }
  ]

  const pointsDisplay = interestDataPoints.map((point) => (
    <DataPoint key={point.label} {...point} />
  ))

  const CustomLabel = ({ isHover, value }) => {
    const diffToValue = 50 - value
    const styleContainer: React.CSSProperties = {
      width: '100%',
      height: '3rem',
      display: 'flex',
      justifyContent: 'space-between'
    }

    const styleTextBox: React.CSSProperties = {
      textAlign: 'center',
      position: 'relative',
      transform: `translateX(${(diffToValue - 1) * -1}%)`,
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      visibility: isHover ? 'hidden' : 'visible'
    }

    const styleValue: React.CSSProperties = {
      color: 'rgba(255,255,255, 0.5)',
      fontSize: '18px',
      fontWeight: 500,
      fontFamily: 'Euclid Circular B',
      lineHeight: '22px'
    }

    const styleLabel: React.CSSProperties = {
      fontSize: '13px',
      fontWeight: 400,
      color: 'rgba(255,255,255, 0.5)',
      fontFamily: 'Euclid Circular B',
      lineHeight: '20px'
    }

    const styleLabelLeft: React.CSSProperties = {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(255,255,255, 0.5)',
      fontFamily: 'Euclid Circular B',
      lineHeight: '20px',
      position: 'relative',
      left: '0'
    }

    const styleLabelRight: React.CSSProperties = {
      fontSize: '12px',
      fontWeight: 400,
      color: 'rgba(255,255,255, 0.5)',
      fontFamily: 'Euclid Circular B',
      lineHeight: '20px',
      position: 'relative',
      right: '0'
    }

    return (
      <div style={styleContainer}>
        <p style={styleLabelLeft}>0%</p>
        <div style={styleTextBox}>
          <p style={styleValue}>{value}%</p>
          <p style={styleLabel}>Utilization</p>
        </div>
        <p style={styleLabelRight}>100%</p>
      </div>
    )
  }

  return (
    <div className="chart-container">
      <div className="points-line">
        <div className="cluster-points">{pointsDisplay}</div>
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          margin={{
            top: 20,
            bottom: 20,
            left: 10,
            right: 10
          }}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}>
          <XAxis
            dataKey="utilizationRate"
            name="Utilization Rate"
            axisLine={false}
            tickLine={false}
            hide={true}
            interval={'preserveStartEnd'}
            allowDataOverflow={false}
          />
          <YAxis name="Interest Rate" hide={true} domain={[0, 'dataMax']} padding={{ bottom: 0 }} />
          <Tooltip
            content={
              <Label
                value={`${activeData?.utilizationRate?.toFixed(2)}%`}
                secondValue={`${activeData?.lendRate?.toFixed(2)}%`}
                thirdValue={`${activeData?.borrowRate?.toFixed(2)}%`}
                labels={{
                  value: 'Utilization',
                  secondValue: 'Earn APY',
                  thirdValue: 'Borrow APR'
                }}
              />
            }
          />
          <ReferenceLine
            x={closestRate}
            stroke={isHover ? 'transparent' : '#CFCFCF'}
            isFront
            label={(props) => <CustomLabel {...props} value={closestRate} />}
          />

          <Line
            type="monotone"
            dataKey="borrowRate"
            stroke="#f8d24a"
            dot={false}
            strokeWidth={1.5}
          />
          <Line type="monotone" dataKey="lendRate" stroke="#00DD5C" dot={false} strokeWidth={1.5} />
        </LineChart>
      </ResponsiveContainer>
      <CustomLabel isHover={isHover} value={closestRate} />
    </div>
  )
}
