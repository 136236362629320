import { isEmptyOrNil } from 'toolbox/account'
import arrow from 'assets/icons/right-arrow.svg'
export interface ParamProps {
  label: string
  value: string
  tooltip?: string
  next?: string
  action?: () => void
  simColor?: string
  currentColor?: string
}

function Param({ label, value, tooltip, next, action, simColor, currentColor }: ParamProps) {
  const hasNext = !isEmptyOrNil(next)
  const isClickable = action ? 'clickable' : ''
  const thin = tooltip ? 'thin-lined' : ''

  return (
    <div className="param">
      <div className={`is-flex ${thin}`}>
        <div className={`label tip-box `}>
          <p>{label}</p>
          {tooltip && <span className="tip-value">{tooltip}</span>}
        </div>
      </div>
      <div className="values">
        {action && !hasNext ? (
          <p className={`value ${isClickable}`} onClick={action}>
            {value}
          </p>
        ) : (
          <p className={`value ${currentColor}`}>{value}</p>
        )}
        {!!hasNext && <img src={arrow} alt="arrow" />}
        {!!hasNext && <p className={`value nextVal ${simColor}`}>{next}</p>}
      </div>
    </div>
  )
}

export default Param
