import { useAppSelector } from 'state/hooks'
import {
  closeForm,
  selectInputVal,
  selectMultiReviewOpen,
  closeMultiReview,
  closeMultiplyForm,
  openMultiReview
} from 'state/slices/ui/form'
import ParamList from '../txForm/ParamList'
import CloseButton from 'components/common/CloseButton'
import { MetaBroker, FormattedPositions } from 'state/mock'
import { useWallet } from '@aptos-labs/wallet-adapter-react'
import { selectTxStatus } from 'state/slices/ui/transaction'
import { aptos } from 'state/tokens'
import { MultiInputContainer } from './MultiInputContainer'
import {
  formatCurrency,
  formatPercentage,
  prettyTokenBal,
  prettyTokenBalWithK
} from 'toolbox/format'
import {
  getWalletBalanceByName,
  selectLoadedWalletBalances
} from 'state/slices/user/walletBalances'
import { MultiFormReview, MultiFormReviewProps } from './MultiReview'
import Slider from './MultiSlider'
import { useState } from 'react'

export interface TxFormProps {
  broker: MetaBroker
  isLoadedUser: boolean
  positions: FormattedPositions
}

function Multiform({ broker, isLoadedUser, positions }: TxFormProps) {
  //state and selectors
  const inputVal = useAppSelector(selectInputVal)
  const amount = parseFloat(inputVal)
  const { account, signAndSubmitTransaction } = useWallet()
  const isTxPending = useAppSelector(selectTxStatus) === 'pending'
  const disabledForm = !isLoadedUser
  const disabledReview = disabledForm || isTxPending
  const [value, setValue] = useState(1)

  function setMax() {}

  //input container props
  const parsedInput = parseFloat(inputVal) || 0
  const price = 100
  const inputDollars = parsedInput > 0 ? parsedInput * price : 0
  const token = aptos
  const inputProps = {
    token,
    inputVal,
    inputDollars,
    setMax
  }

  //params
  const healthFactor = 56
  const healthFactorParam = {
    label: 'Health factor',
    value: healthFactor + 'x',
    tooltip: 'Health simulation of next position required to be 1.2x or higher for user safety'
  }

  const totalExposure = 12.45
  const totalExposureParam = {
    label: 'Total exposure',
    value: formatCurrency(totalExposure)
  }

  const totalstAPTExposure = 10.2332
  const totalstAPTExposureParam = {
    label: 'Total stAPT exposure',
    value: prettyTokenBal(totalstAPTExposure)
  }
  const totalAPTDebtParam = {
    label: 'Total APT debt',
    value: prettyTokenBal(2.22)
  }

  const netAPY = 24.43
  const netAPYParam = {
    label: 'Net APY',
    value: formatPercentage(netAPY)
  }

  const multiParams = [
    healthFactorParam,
    totalExposureParam,
    totalstAPTExposureParam,
    totalAPTDebtParam,
    netAPYParam
  ]

  //more params
  const lendAPY = 12.43
  const stAPTAPY = 2.45
  const borrowAPY = 24.43
  const maxLTV = 80
  const availableLiquidity = 500000000
  const spFee = 0.2

  const lendAPYParam = {
    label: 'Lend APY',
    value: formatPercentage(lendAPY)
  }

  const stAPTAPYParam = {
    label: 'stAPT APY',
    value: formatPercentage(stAPTAPY)
  }

  const borrowAPYParam = {
    label: 'Borrow APY',
    value: formatPercentage(borrowAPY)
  }

  const maxLTVParam = {
    label: 'Max LTV',
    value: formatPercentage(maxLTV)
  }

  const availableLiquidityParam = {
    label: 'Available liquidity',
    value: prettyTokenBalWithK(availableLiquidity)
  }

  const spFeeParam = {
    label: 'MovePosition fee',
    value: formatPercentage(spFee)
  }

  const moreParams = [
    lendAPYParam,
    stAPTAPYParam,
    borrowAPYParam,
    maxLTVParam,
    availableLiquidityParam,
    spFeeParam
  ]

  //below params
  const balances = useAppSelector(selectLoadedWalletBalances)
  const balance = getWalletBalanceByName(balances, token.name)
  const walletBalanceParam = {
    label: 'Wallet balance',
    value: `${prettyTokenBal(balance)} ${token?.ticker}`
  }
  const belowParams = [walletBalanceParam]

  //tx review
  const multiReviewOpen = useAppSelector(selectMultiReviewOpen)

  //form actions and button functions
  const action = () => {
    if (multiReviewOpen) {
      //   formAction(
      //     selectedTab,
      //     broker,
      //     amount,
      //     account?.address as string,
      //     positions,
      //     signAndSubmitTransaction,
      //     brokerNames
      //   )
    } else {
      openMultiReview()
    }
  }
  const actionButtonProps = {
    className: 'sp-btn primary large full-w send-btn',
    onClick: action,
    disabled: disabledForm
  }
  const buttonText = 'Review'

  const multiReviewProps: MultiFormReviewProps = {
    closeForm: closeMultiplyForm,
    inputVal,
    token,
    multiParams,
    closeMultiReview,
    action,
    disabled: disabledReview
  }

  if (multiReviewOpen) {
    return <MultiFormReview {...multiReviewProps} />
  }

  return (
    <div className={`tx-form `}>
      <CloseButton cb={closeMultiplyForm} />
      <p className="m-title">Multiply</p>
      <MultiInputContainer {...inputProps} />
      <Slider min={1} max={20} step={1} value={value} onChange={setValue} />
      <ParamList params={multiParams} more={moreParams} />
      <button {...actionButtonProps}>{buttonText}</button>
      <ParamList params={belowParams} />
    </div>
  )
}

export default Multiform
