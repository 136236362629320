import { Link, useLocation } from 'react-router-dom'
import mpBasic from 'assets/logos/mp-basic.svg'
import CloseButton from 'components/common/CloseButton'

export function BurgerNav({ cb }: { cb: () => void }) {
  const { pathname } = useLocation()
  const dashSelected = pathname === '/'
  const statsSelected = pathname.includes('/markets')
  const activitySelected = pathname.includes('/activity')

  const selectedStyle = 'selected'

  return (
    <div className="burger-nav">
      <img src={mpBasic} alt="superposition" className="burg-logo-left" />
      <CloseButton cb={cb} />
      <div className="burg-links">
        <Link to="/" onClick={cb} className={` ${dashSelected ? selectedStyle : ''}`}>
          Dashboard
        </Link>
        <Link to="/markets" onClick={cb} className={` ${statsSelected ? selectedStyle : ''}`}>
          Markets
        </Link>
        <Link to="/activity" onClick={cb} className={` ${activitySelected ? selectedStyle : ''}`}>
          Activity
        </Link>
      </div>
    </div>
  )
}
