import { ResponsiveContainer, Treemap } from 'recharts'
import { useAppSelector } from 'state/hooks'
import { selectBrokersLoaded } from 'state/slices/app/brokers'
import { formatCurrencyCompact } from 'toolbox/format'
import { SimpleSpinner } from './SimpleSpinner'
import { isEmptyOrNil } from 'toolbox/account'

const SimpleTreeMap = ({ data, colors }) => {
  const SIZES = [54, 36, 20, 16, 12, 10, 8, 8, 8, 8, 8, 8, 8]
  const isBrokersLoaded = useAppSelector(selectBrokersLoaded)

  return isBrokersLoaded ? (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        data={data}
        dataKey="balance"
        aspectRatio={4 / 3}
        stroke="white"
        fill="rgba(0, 0, 0, 0.5)"
        animationDuration={1000}
        animationEasing={'ease-out'}
        content={<CustomizedContent colors={colors} data={data} sizes={SIZES} />}
      />
    </ResponsiveContainer>
  ) : (
    <ResponsiveContainer width="100%" height="100%">
      <SimpleSpinner />
    </ResponsiveContainer>
  )
}

export default SimpleTreeMap

const CustomizedContent = (props: any) => {
  const { root, depth, x, y, width, height, index, colors, name, sizes, balance } = props

  if (isEmptyOrNil(root.children)) {
    return <SimpleSpinner />
  }

  const fontSize = sizes[Math.floor((index / root.children.length) * 6)]
  const nameY = y + height / 2
  const infoY = nameY + fontSize / 1.25
  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 ? colors[Math.floor((index / root.children.length) * 6)] : 'none',
          stroke: 'rgba(0, 0, 0, 0.5)',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10)
        }}
      />
      {depth === 1 ? (
        <text
          x={x + width / 2}
          y={nameY}
          textAnchor="middle"
          fill="rgba(255, 255, 255, 0.95)"
          color="rgba(255, 255, 255, 0.95)"
          stroke="rgba(255, 255, 255, 0.95)"
          style={{ fontSize }}>
          {name}
        </text>
      ) : null}
      {depth === 1 ? (
        <text
          x={x + width / 2}
          y={infoY}
          textAnchor="middle"
          fill="rgba(255, 255, 255, 0.95)"
          color="rgba(255, 255, 255, 0.95)"
          stroke="rgba(255, 255, 255, 0.95)"
          strokeWidth={0.25}
          style={{ fontSize: fontSize / 2 }}>
          {formatCurrencyCompact(balance)}
        </text>
      ) : null}

      {depth === 1 ? (
        <text x={x + 4} y={y + 18} fill="#fff" fontSize={54} fillOpacity={0.9}>
          {index + 1}
        </text>
      ) : null}
    </g>
  )
}
