import tooltip from 'assets/icons/tooltip.svg'
import closeIcon from 'assets/icons/close-white.svg'
import redTooltip from 'assets/icons/tooltip-red.svg'
import orangeTooltip from 'assets/icons/tooltip-orange.svg'
import yellowTooltip from 'assets/icons/tooltip-yellow.svg'

export interface InfoBoxProps {
  color: 'blue' | 'orange' | 'red' | 'yellow'
  hideBoxCallback?: () => void
  showBox: boolean
  text: string
  fullWidth?: boolean
  noTopMargin?: boolean
}

const tooltipMap = {
  blue: tooltip,
  orange: orangeTooltip,
  red: redTooltip,
  yellow: yellowTooltip
}

const InfoBox = ({
  color,
  hideBoxCallback,
  showBox,
  fullWidth,
  noTopMargin,
  text
}: InfoBoxProps) => {
  const hideBoxClass = !showBox ? 'hide' : ''
  const tooltipIcon = tooltipMap[color]
  const fullWidthClass = fullWidth ? 'full-width' : ''
  const noTopClass = noTopMargin ? 'no-top' : ''

  return (
    <div className={`info-box ${color} ${hideBoxClass} ${fullWidthClass} ${noTopClass}`}>
      <img src={tooltipIcon} alt="tooltip" />
      <p>{text}&nbsp;&nbsp;</p>
      {!!hideBoxCallback && (
        <img src={closeIcon} alt="close" className="clickable" onClick={hideBoxCallback} />
      )}
    </div>
  )
}

export default InfoBox
