import { openWidgetSignIn } from 'state/slices/ui/widget'
import splash from 'assets/graphics/splash.svg'
import mobile_splash from 'assets/graphics/mobile-splash.svg'
import { useAppSelector } from 'state/hooks'
import { selectIsBlocked, showBlock } from 'state/slices/ui/dash'

export function Splash() {
  const isBlocked = useAppSelector(selectIsBlocked)

  return (
    <>
      <div className="splash-placeholder" />
      <div className="splash">
        <div className="inner-splash section">
          <div className="splash-text">
            <h1>
              Borrow/lend <br />
              on Movement
              <span className="blue-circle">.</span>
            </h1>
            <h2>
              Connect a wallet to view your <br />
              collaterals and liabilities
            </h2>
            <button
              className="sp-btn sign-in large"
              onClick={isBlocked ? showBlock : openWidgetSignIn}>
              Connect wallet
            </button>
          </div>
          <img src={splash} alt="superposition" className="desk-image" />
        </div>
        <div className="inner-splash-mobile">
          <div className="splash-text">
            <h1>
              Borrow/lend <br />
              on Movement
              <span className="blue-circle">.</span>
            </h1>
            <h2>
              Connect a wallet to view your <br />
              collaterals and liabilities
            </h2>
            <button
              className="sp-btn sign-in large"
              onClick={isBlocked ? showBlock : openWidgetSignIn}>
              Connect wallet
            </button>
          </div>
          <img src={mobile_splash} alt="superposition" className="mobile-image" />
        </div>
      </div>
    </>
  )
}
