import { createAsyncThunk } from '@reduxjs/toolkit'
import { AppMeta } from 'state/slices/app/meta'
import { tokenMetaMap, chains } from 'state/tokens'

export const fetchMeta = createAsyncThunk('meta/fetchAll', async (): Promise<AppMeta> => {
  try {
    await new Promise((resolve) => setTimeout(resolve, 1000))
    return { tokens: tokenMetaMap, chains }
  } catch (e: any) {
    console.log(e)
  }
})
