import { MetaBroker } from 'state/mock'
import { calcUtil } from 'toolbox/calc'
import { formatPercentage, prettyTokenBal } from 'toolbox/format'
import ParamList from 'components/txForm/ParamList'

export interface MobileMarketCardProps {
  broker: MetaBroker
}

export function MobileMarketCard({ broker }: MobileMarketCardProps) {
  const brokerBorrowed = broker?.scaledTotalBorrowedUnderlying
  const totalAvailable = broker?.scaledAvailableLiquidityUnderlying
  const brokerTotal = brokerBorrowed + totalAvailable
  const utilization = calcUtil(broker)
  const t = broker.tokenMeta

  //broker params and projections
  const brokerStats = [
    {
      label: 'Total Available In Broker',
      value: prettyTokenBal(totalAvailable),
      tooltip: `Liquidity available for borrowing or withdrawal in tokens`
    },
    {
      label: 'Total Loaned By Broker',
      value: prettyTokenBal(brokerBorrowed),
      tooltip: `Liquidity currently loaned to borrowers in tokens`
    },
    {
      label: 'Total Supplied In Broker',
      value: prettyTokenBal(brokerTotal),
      tooltip: 'Liquidity supplied in tokens'
    },
    {
      label: 'Utilization',
      value: formatPercentage(utilization),
      tooltip: 'Ratio of debt / collateral.  High utilization increases interest'
    }
  ]

  return (
    <div className="mobile-broker-card">
      <div className="top-card">
        <div className="basic-meta small">
          <img src={t.logo} alt={t.name} />
          <div className="names">
            <p className="name">{t.ticker}</p>
            <p className="full-name">{t.displayName}</p>
          </div>
        </div>
      </div>
      <hr className="hr" />
      <ParamList params={brokerStats} />
    </div>
  )
}
