import { createAsyncThunk } from '@reduxjs/toolkit'
import { dismissNotifyThrow, toastLoad, toastSuccess } from 'toolbox/toast'
import { FormattedPositions, MetaBroker } from 'state/mock'
import { SDK_CONTEXT } from 'state/context'
import { SPortfolio, SPosition, TransactionArgs } from '@concordia/super-sdk/src/io'
import { postTransactionRefresh } from 'state/fetch'
import { FormTab, closeForm } from 'state/slices/ui/form'
import { eMessage, scaleDown, scaleUp } from 'toolbox/format'
import { SuperpositionAptosSDK } from '@concordia/super-aptos-sdk'
import {
  BORROW_ACTION,
  REPAY_ACTION,
  SUPPLY_ACTION,
  TxAction,
  WITHDRAW_ACTION,
  setTxSuccessState,
  txStatusPending
} from 'state/slices/ui/transaction'
import { BasicPosition, PacketResponse, PortfolioState } from '@concordia/super-json-api-client'
import { isEmptyOrNil } from 'toolbox/account'
import { SignAndSubmitTransactionCallback } from './doMoneyGun'
import { InputTransactionData } from '@aptos-labs/wallet-adapter-core'
import { AptosConfig, Aptos, Network, Hex } from '@aptos-labs/ts-sdk'

export const DEPOSIT_TAB = 'Supply'
export const WITHDRAW_TAB = 'Withdraw'
export const BORROW_TAB = 'Borrow'
export const REPAY_TAB = 'Repay'

export const WITHDRAW = 'withdraw'
export const SUPPLY_COLLATERAL = 'supply_collateral'
export const BORROW = 'borrow'
export const REPAY = 'repay'

export type TxType = typeof WITHDRAW | typeof SUPPLY_COLLATERAL | typeof BORROW | typeof REPAY

export interface WaitArgs {
  transactionHash: string
  options: {
    checkSuccess: boolean
  }
}

export interface AccountArgs {
  accountAddress: string
  options?: any
}

export const tabToType: { [key: string]: TxType } = {
  [WITHDRAW_TAB]: WITHDRAW,
  [DEPOSIT_TAB]: SUPPLY_COLLATERAL,
  [BORROW_TAB]: BORROW,
  [REPAY_TAB]: REPAY
}

const TxTypeToActionMap: { [key in TxType]: TxAction } = {
  [WITHDRAW]: WITHDRAW_ACTION,
  [SUPPLY_COLLATERAL]: SUPPLY_ACTION,
  [BORROW]: BORROW_ACTION,
  [REPAY]: REPAY_ACTION
}

export const typeToTab: { [key in TxType]: string } = {
  [WITHDRAW]: WITHDRAW_TAB,
  [SUPPLY_COLLATERAL]: DEPOSIT_TAB,
  [BORROW]: BORROW_TAB,
  [REPAY]: REPAY_TAB
}

export interface TxReqPayload {
  txType: TxType
  amount: number
  broker: MetaBroker
  address: string
  positions: FormattedPositions
  signAndSub: SignAndSubmitTransactionCallback
  brokerNames: string[]
}

export const doTx = (txType: TxType) => {
  const type = txType
  return createAsyncThunk(type, async (payload: TxReqPayload): Promise<any> => {
    toastLoad('Building transaction...')
    txStatusPending({ type, info: 'Signing transaction...' })
    const envNetwork = process.env.REACT_APP_APTOS_NETWORK_SHORT
    const useNetwork = envNetwork === 'mainnet' ? Network.MAINNET : Network.TESTNET
    const aptosConfig = new AptosConfig({
      fullnode: process.env.REACT_APP_APTOS_MAIN_URL,
      faucet: process.env.REACT_APP_APTOS_FAUCET_URL
    })
    const aptos = new Aptos(aptosConfig)
    const superSdk = SDK_CONTEXT.superSdk
    const rootAddr = process.env.REACT_APP_APTOS_ROOT_ADDRESS
    const superAptosSDK = new SuperpositionAptosSDK(rootAddr)

    if (!rootAddr || !superSdk || !envNetwork || !aptos || !superAptosSDK) {
      console.log('rootAddress: ', rootAddr)
      console.log('superSdk: ', superSdk)
      console.log('Env network: ', envNetwork)
      console.log('aptosSDK: ', aptos)
      console.log('SuperpositionAptosSDK: ', superAptosSDK)
      dismissNotifyThrow(
        'Missing required system configuration',
        'Check the console for more information'
      )
    }

    const { broker, amount, address } = payload
    if (!broker || !amount || !address) {
      dismissNotifyThrow(
        'Missing arguments',
        'The transaction request is missing required arguments, please try again'
      )
    }

    const depNoteDecimals = broker.depositNote.decimals
    const loanNoteDecimals = broker.loanNote.decimals
    const decimals = broker.underlyingAsset.decimals

    const freshPortfolioState = await superSdk.fetcher.fetchPortfolioWithRisk(address)
    if (!freshPortfolioState) {
      dismissNotifyThrow(
        'Testnet Overload!',
        `We're currently unable to load the portfolio data due to the immense amount of traffic occurring on Movement’s testnet at this time.
This increased load can lead to temporary limitations, which is why some requests aren’t going through as expected.  Please refresh or try again later.  This is what testnets are for!  You are actively helping the team ensure a smooth mainnet launch.`
      )
    }

    const currentPortfolioState = buildCurrentPortfolioBasicState(freshPortfolioState)
    const depNoteAmount = scaleUp(amount, depNoteDecimals) / broker.depositNoteExchangeRate
    const loanNoteAmount = scaleUp(amount, loanNoteDecimals) / broker.loanNoteExchangeRate
    const scaledAmount = scaleUp(amount, decimals)

    let amountValue = ''
    switch (txType) {
      case SUPPLY_COLLATERAL:
        amountValue = Math.floor(scaledAmount).toString()
        break
      case WITHDRAW:
        amountValue = Math.floor(depNoteAmount).toString()
        break
      case REPAY:
        amountValue = Math.floor(loanNoteAmount).toString()
        break
      case BORROW:
        amountValue = Math.floor(scaledAmount).toString()
        break
      default:
        dismissNotifyThrow(
          'Invalid transaction type',
          `The transaction of type ${txType} is invalid`
        )
    }

    const accountArgs: AccountArgs = {
      accountAddress: payload.address
    }
    const aptResource = '0x1::coin::CoinStore<0x1::aptos_coin::AptosCoin>'

    async function checkAPTBalance() {
      toastLoad('Checking APT balance...')
      try {
        toastLoad('Checking gas balance...')
        const resources = await aptos.getAccountResources(accountArgs)
        //find aptResources in resources
        const gasToken: any = resources.find((t) => t.type === aptResource)
        const gasBal = gasToken.data?.coin?.value || 0
        const hasGas = gasBal > 0

        if (!hasGas) {
          throw new Error(
            'Use the faucet in your wallet to get Testnet APT tokens.  If you have, try refreshing the page.'
          )
        }
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow('APT not found', eMessage(e))
      }
    }

    const txArgs: TransactionArgs = {
      brokerName: broker.underlyingAsset.name,
      amount: amountValue,
      network: 'Aptos',
      signerPubkey: address,
      currentPortfolioState
    }

    async function checkRegisteredToken({
      address,
      broker
    }: {
      address: string
      broker: MetaBroker
    }) {
      const coinType = broker.underlyingAsset.networkAddress

      const resources = await aptos.getAccountResources(accountArgs)
      const hasCoinRegistered = resources.find((r) => r.type.includes(coinType))
      if (!hasCoinRegistered) {
        toastLoad('Registering token...')
        const registerTx: InputTransactionData = {
          sender: address,
          data: {
            function: '0x1::managed_coin::register',
            typeArguments: [coinType],
            functionArguments: []
          }
        }
        try {
          await payload.signAndSub(registerTx)
        } catch (e: any) {
          console.error(e)
          dismissNotifyThrow('Token Not Registered', eMessage(e))
        }
      }
    }

    async function fetchPacket({ txType, txArgs }: { txType: TxType; txArgs: TransactionArgs }) {
      let packet: PacketResponse
      toastLoad(`Fetching ${txType} packet...`)

      try {
        switch (txType) {
          case SUPPLY_COLLATERAL:
            packet = await superSdk.lendV2(txArgs)
            break
          case WITHDRAW:
            packet = await superSdk.redeemV2(txArgs)
            break
          case BORROW:
            packet = await superSdk.borrowV2(txArgs)
            break
          case REPAY:
            packet = await superSdk.repayV2(txArgs)
            break
        }
        return packet
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow(
          `Testnet Overload!`,
          `We're currently unable to load the portfolio data due to the immense amount of traffic occurring on Movement’s testnet at this time.
This increased load can lead to temporary limitations, which is why some requests aren’t going through as expected.  Please refresh or try again later.  This is what testnets are for!  You are actively helping the team ensure a smooth mainnet launch.`
        )
      }
    }

    async function signAndSubmitter({
      packet,
      broker
    }: {
      packet: PacketResponse
      broker: MetaBroker
    }) {
      toastLoad('Signing transaction...')
      let ix: InputTransactionData
      try {
        const packetHex = Hex.fromHexString(packet.packet)
        const ar = packetHex.toUint8Array()

        switch (txType) {
          case SUPPLY_COLLATERAL:
            ix = superAptosSDK.superLendV2Ix(ar, broker.underlyingAsset.networkAddress, address)
            break
          case WITHDRAW:
            ix = superAptosSDK.superRedeemV2Ix(ar, broker.underlyingAsset.networkAddress, address)
            break
          case BORROW:
            ix = superAptosSDK.superBorrowV2Ix(ar, broker.underlyingAsset.networkAddress, address)
            break
          case REPAY:
            ix = superAptosSDK.superRepayV2Ix(ar, broker.underlyingAsset.networkAddress, address)
            break
          default:
            throw new Error(`Invalid transaction type: ${txType}`)
        }
        const hash = await payload.signAndSub(ix)
        return hash
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow('Transaction Not Submitted', eMessage(e))
      }
    }

    async function updateSuccessStateAndWait({
      hash,
      txArgs,
      broker,
      action
    }: {
      hash: string
      txArgs: TransactionArgs
      broker: MetaBroker
      action: string
    }) {
      toastLoad('Awaiting finality...')
      try {
        const args: WaitArgs = {
          transactionHash: hash,
          options: {
            checkSuccess: true
          }
        }
        const result = await aptos.waitForTransaction(args)
        console.log(`${action} result`, result)
        toastSuccess(`${typeToTab[action]} success!`)
        setTxSuccessState({
          amount: txArgs.amount,
          ticker: broker.underlyingAsset.name,
          txHash: hash,
          action: TxTypeToActionMap[txType],
          decimals: broker.underlyingAsset.decimals
        })
      } catch (e: any) {
        console.error(e)
        dismissNotifyThrow('Transaction Not Completed', eMessage(e), hash)
      }
    }

    //execute all transaction steps
    await checkAPTBalance()
    const isBorrow = txType === BORROW
    if (isBorrow) {
      await checkRegisteredToken({ address, broker })
    }
    const packet = await fetchPacket({ txType, txArgs })
    const action = txType.toLowerCase()
    const hash = await signAndSubmitter({ packet, broker })
    await updateSuccessStateAndWait({ hash: hash.hash, txArgs, broker, action })
    postTransactionRefresh(address, payload.brokerNames)
    closeForm()
  })
}

export function buildCurrentPortfolioBasicState(freshPortfolioState: SPortfolio): {
  collaterals: BasicPosition[]
  liabilities: BasicPosition[]
} {
  if (isEmptyOrNil(freshPortfolioState)) {
    return { collaterals: [], liabilities: [] }
  }

  const mapToBasicPosition = (position: SPosition) => ({
    instrumentId: position.instrument.name,
    amount: position.amount
  })

  return {
    collaterals: freshPortfolioState.collaterals.map(mapToBasicPosition),
    liabilities: freshPortfolioState.liabilities.map(mapToBasicPosition)
  }
}

export function buildNextPortfolioState(
  current: PortfolioState,
  formTab: FormTab,
  amount: number,
  broker: MetaBroker
): PortfolioState {
  if (isEmptyOrNil(current)) {
    return {
      collaterals: [],
      liabilities: []
    }
  }
  if (isEmptyOrNil(broker)) {
    return {
      collaterals: [],
      liabilities: []
    }
  }
  const depNoteName = broker.depositNote.name
  const loanNoteName = broker.loanNote.name
  const depDecimals = broker.depositNote.decimals
  const loanDecimals = broker.loanNote.decimals
  const nanCheckedDepositER =
    isNaN(broker.depositNoteExchangeRate) ||
    broker.depositNoteExchangeRate === null ||
    broker.depositNoteExchangeRate === 0
      ? 1
      : broker.depositNoteExchangeRate
  const depNoteAmount = scaleUp(amount, depDecimals) / nanCheckedDepositER

  const nanCheckedLoanER =
    isNaN(broker.loanNoteExchangeRate) ||
    broker.loanNoteExchangeRate === null ||
    broker.loanNoteExchangeRate === 0
      ? 1
      : broker.loanNoteExchangeRate
  const loanNoteAmount = scaleUp(amount, loanDecimals) / nanCheckedLoanER

  let matchedCollateralsLend = []
  const hasCollateralMatch = current.collaterals.some((c) => c.instrumentId === depNoteName)
  if (!hasCollateralMatch) {
    matchedCollateralsLend.push({
      instrumentId: depNoteName,
      amount: depNoteAmount.toString()
    })
  }
  const mappedCollateralsLend = current.collaterals.map((c) => {
    if (c.instrumentId === depNoteName) {
      const numAmount = Number(c.amount) + depNoteAmount
      return {
        instrumentId: c.instrumentId,
        amount: numAmount.toString()
      }
    }
    return c
  })
  const nextCollateralsLend = matchedCollateralsLend.concat(mappedCollateralsLend)

  let nextCollateralsRedeem = []
  nextCollateralsRedeem = current.collaterals.map((c) => {
    const numAmount = Number(c.amount) - depNoteAmount
    if (c.instrumentId === depNoteName) {
      return {
        instrumentId: c.instrumentId,
        amount: numAmount.toString()
      }
    }
    return c
  })

  let matchedLiabiltiesBorrow = []
  const hasLoanMatch = current.liabilities.some((l) => l.instrumentId === loanNoteName)
  if (!hasLoanMatch) {
    matchedLiabiltiesBorrow.push({
      instrumentId: loanNoteName,
      amount: loanNoteAmount.toString()
    })
  }
  const mappedLiabilitiesBorrow = current.liabilities.map((l) => {
    if (l.instrumentId === loanNoteName) {
      const numAmount = Number(l.amount) + loanNoteAmount
      return {
        instrumentId: l.instrumentId,
        amount: numAmount.toString()
      }
    }
    return l
  })
  const nextLiabilitiesBorrow = matchedLiabiltiesBorrow.concat(mappedLiabilitiesBorrow)

  let nextLiabilitiesRepay = []

  nextLiabilitiesRepay = current.liabilities.map((l) => {
    if (l.instrumentId === loanNoteName) {
      const numAmount = Number(l.amount) - loanNoteAmount
      return {
        instrumentId: l.instrumentId,
        amount: numAmount.toString()
      }
    }
    return l
  })

  switch (formTab) {
    case DEPOSIT_TAB:
      return {
        collaterals: nextCollateralsLend,
        liabilities: current.liabilities
      }
    case WITHDRAW_TAB:
      return {
        collaterals: nextCollateralsRedeem,
        liabilities: current.liabilities
      }
    case BORROW_TAB:
      return {
        collaterals: current.collaterals,
        liabilities: nextLiabilitiesBorrow
      }
    case REPAY_TAB:
      return {
        collaterals: current.collaterals,
        liabilities: nextLiabilitiesRepay
      }
  }
}

export function getNextWalletBalance(tab: FormTab, amount: number, walletBalance: number): number {
  if (tab === DEPOSIT_TAB || tab === REPAY_TAB) {
    return walletBalance - amount
  } else {
    return walletBalance + amount
  }
}

export function shouldGetRiskEval(tab: FormTab, nextPort: PortfolioState) {
  const hasCollatBalance = nextPort.collaterals.some((c) => Number(c.amount) > 0)
  const hasLiabBalance = nextPort.liabilities.some((l) => Number(l.amount) > 0)

  if (tab === WITHDRAW_TAB && !hasCollatBalance) {
    return false
  } else if (tab === REPAY_TAB && !hasLiabBalance) {
    return false
  } else if (tab === BORROW_TAB && !hasCollatBalance) {
    return false
  } else {
    return true
  }
}

export function getNextPosition(nextPort: PortfolioState, tab: FormTab, broker: MetaBroker) {
  let instrument, positionType, exchangeRate
  if (!broker || !nextPort || !tab) {
    return 0
  }

  switch (tab) {
    case DEPOSIT_TAB:
    case WITHDRAW_TAB:
      instrument = broker.depositNote
      positionType = nextPort.collaterals
      exchangeRate = broker.depositNoteExchangeRate
      break
    case BORROW_TAB:
    case REPAY_TAB:
      instrument = broker.loanNote
      positionType = nextPort.liabilities
      exchangeRate = broker.loanNoteExchangeRate
      break
  }

  const p = positionType.find((item) => item.instrumentId === instrument.name)
  const decimals = instrument.decimals
  const scaledPosition = p ? scaleDown(Number(p.amount), decimals) * exchangeRate : 0
  return scaledPosition
}

export function diffNextPosition(
  amountUnderlying: number,
  nextPos: number,
  isAdd: boolean
): number {
  return isAdd ? (nextPos += amountUnderlying) : (nextPos -= amountUnderlying)
}
