import { ReactNode, useEffect } from 'react'

export function MobileModal(props: {
  children: ReactNode
  open: boolean
  onClose: () => void
  transition?: string
}) {
  const { open, onClose, transition } = props

  useEffect(() => {
    const close = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onClose()
      }
    }
    window.addEventListener('keydown', close)
    return () => window.removeEventListener('keydown', close)
  }, [onClose])

  const live = open ? 'live' : ''
  const ani = transition ? transition : ''

  return (
    <div className={`mobile-modal ${open ? 'is-active' : ''} ${ani}`} id="modal">
      <div className="modal-background-mobile" onClick={onClose}></div>
      <div className={`modal-content-mobile ${live} ${ani}`}>{props.children}</div>
    </div>
  )
}

export default MobileModal
