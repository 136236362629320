import Modal from 'layout/Modal'
import { useAppSelector } from 'state/hooks'
import { selectAptosChain } from 'state/slices/app/meta'
import {
  closeWidget,
  selectOpen,
  selectWidgetOpen,
  openSelect,
  closeSelect
} from 'state/slices/ui/widget'
import Widget from './Widget'
import MobileModal from 'layout/MobileModal'

function WidgetContainer() {
  const selOpen = useAppSelector(selectOpen)
  const widgetOpen = useAppSelector(selectWidgetOpen)
  const chain = useAppSelector(selectAptosChain)

  const widgetProps = {
    closeWidget,
    openSelect,
    chain,
    selectOpen: selOpen,
    closeSelect
  }

  return (
    <>
      <Modal open={widgetOpen} onClose={closeWidget} transition={'top-down'}>
        <Widget {...widgetProps} />
      </Modal>
      <MobileModal open={widgetOpen} onClose={closeWidget} transition={'top-down'}>
        <Widget {...widgetProps} />
      </MobileModal>
    </>
  )
}

export default WidgetContainer
