import orangeTip from 'assets/icons/tooltip-orange.svg'
import InfoBox, { InfoBoxProps } from 'components/common/InfoBox'
import { Link } from 'react-router-dom'
import { AppError } from 'state/slices/ui/transaction'

export function ErrorBoundaryModal({ title, message, details, clear }: AppError) {
  const infoBoxProps: InfoBoxProps = {
    color: 'yellow',
    showBox: true,
    text: message,
    fullWidth: true,
    noTopMargin: true
  }
  return (
    <div className="app-error-modal">
      <img src={orangeTip} alt="warning" className="big-i" />
      <h1>{title}</h1>
      <hr className="hr" />
      <InfoBox {...infoBoxProps} />
      {!!details && details}

      <Link to="/" onClick={clear}>
        <button className="sp-btn tertiary full-w">Go to Dashboard</button>
      </Link>
    </div>
  )
}
